import React, { useEffect, useRef } from "react";
import Link from "next/link";
import CircleType from "circletype";
import Marquee from "react-fast-marquee";
import Intro_video from "../../components/intro_video";

const hero = () => {
  const circleInstance = useRef();
  useEffect(() => {
    // Add any additional JavaScript logic you need here
  }, []);

  return (
    <section id="hero-section" className="relative pb-[100px] pt-20 md:pt-32">
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 block dark:hidden h-full">
        <img src="/images/gradient.jpg" alt="gradient" className="h-full w-full" />
      </picture>
      <picture className="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
        <img src="/images/gradient_dark.jpg" alt="gradient dark" className="h-full w-full" />
      </picture>

      <div className="flex justify-center w-full">
        <div className="max-w-[1200px] w-full px-4 md:px-32 lg:pb-12 grid grid-cols-7  gap-2">
          <div className="relative overflow-visible  left">
            <div className="absolute  top-40 left-[-10] -rotate-12 overflow-visible ">
              <img src="/images/hero/3D_elements_left.png" alt="" loading="lazy" className="overflow-visible object-none animate-fly z-20 pointer-events-none" />
            </div>
          </div>
          <div className="h-full flex flex-col items-start justify-center gap-4 col-span-5">
            <div className="w-full flex flex-col items-start justify-center py-10 md:py-20">
              <h1 className="text-jacarta-700 font-bold font-display mb-6 text-center text-5xl dark:text-white lg:text-5xl xl:text-6xl">Imagine a cozy place...</h1>
              <p className="dark:text-jacarta-200 mb-8 text-center text-lg">...your home away from home, where you and your community can grow and flourish. Easily create your own immersive and interactive virtual world to share with each other. Create lore, play games, and experience fun together. Give rewards and recognitions to each other that highlight memorable moments. Everyone has a community to build, now you can give them a home.</p>
              <div className="w-full flex flex-col md:flex-row text-center md:text-left space-x-4 sm:gap-3 ">
                <Link href="https://store.steampowered.com/app/1920720?utm_source=website&utm_campaign=front_page">
                  <a className="w-full bg-white text-accent border border-accent hover:bg-slate-400 hover:border-accent-dark  rounded-full py-5 font-semibold transition-all flex items-center justify-center">Download on Windows</a>
                </Link>
                <div></div>
                <Link href="https://github.com/mtion-tv/MtionSDK">
                  <a className="w-full bg-accent text-white hover:bg-accent-dark rounded-full font-semibold transition-all py-5 flex items-center justify-center">Get the SDK</a>
                </Link>
              </div>
            </div>
          </div>
          <div className="relative right">
            <div className="absolute  top-[180px] right-[30px] rotate-10 overflow-visible ">
              <img src="/images/hero/3D_elements_right.png" alt="" loading="lazy" className="overflow-visible object-none animate-fly z-20 pointer-events-none" />
            </div>
          </div>
        </div>
      </div>

      <div className="mx-0 my-0 pt-[50px] xl:pt-[0px] w-full z-10 h-[100px]">
        <div className="h-[100px] bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500 ">
          <div className="background-text">
            <Marquee className="h-[100px]" gradient={false}>
              <h2 className="">&nbsp;&nbsp;Create mini games&nbsp;&nbsp;&nbsp; &#x2022;</h2>
              <h2 className=""> &nbsp;&nbsp;&nbsp;Build your clubhouse&nbsp;&nbsp;&nbsp; &#x2022;</h2>
              <h2 className=""> &nbsp;&nbsp;&nbsp;Set your vibe&nbsp;&nbsp;&nbsp; &#x2022; </h2>
              <h2 className=""> &nbsp;&nbsp;&nbsp;Hang with your friends&nbsp;&nbsp;&nbsp; &#x2022; </h2>
            </Marquee>
          </div>
        </div>
      </div>
    </section>
  );
};

export default hero;
